
// third-party
import { FormattedMessage } from 'react-intl';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';

// assets
import {
  KyberNetwork,
  Messages2,
  Calendar1,
  Kanban,
  Profile2User,
  Bill,
  UserSquare,
  ShoppingBag,
  TicketDiscount,
  CallIncoming,
  People,
  BoxTime,
  Card,
  Ship,
  MoneyChange,
  MoneyAdd,
  User
} from 'iconsax-react';

// icons
const icons = {
  applications: KyberNetwork,
  allcustomer: People,
  chat: Messages2,
  calendar: Calendar1,
  kanban: Kanban,
  customer: Profile2User,
  invoice: Bill,
  profile: UserSquare,
  ecommerce: ShoppingBag,
  coupons: TicketDiscount,
  contact: CallIncoming,
  allorder: BoxTime,
  blogs: Card,
  ship: Ship,
  User:User,
  MoneyAdd: MoneyAdd,
  MoneyChange: MoneyChange
};

// ==============================|| MENU ITEMS - APPLICATIONS ||============================== //

const applications = {
  id: 'group-applications',
  title: <FormattedMessage id="applications" />,
  icon: icons.applications,
  type: 'group',
  children: [
    // {
    //   id: 'chat',
    //   title: <FormattedMessage id="chat" />,
    //   type: 'item',
    //   url: '/apps/chat',
    //   icon: icons.chat,
    //   breadcrumbs: false
    // },
    // {
    //   id: 'Contact',
    //   title: <FormattedMessage id="Contact" />,
    //   type: 'item',
    //   url: '/apps/contact',
    //   icon: icons.call,
    //   breadcrumbs: false
    // },

    // {
    //   id: 'calendar',
    //   title: <FormattedMessage id="calendar" />,
    //   type: 'item',
    //   url: '/apps/calendar',
    //   icon: icons.calendar
    // },
    // {
    //   id: 'kanban',
    //   title: <FormattedMessage id="kanban" />,
    //   type: 'item',
    //   icon: icons.kanban,
    //   url: '/apps/kanban/board'
    // },
    // {
    //   id: 'customer',
    //   title: <FormattedMessage id="Customer" />,
    //   type: 'collapse',
    //   icon: icons.customer,
    //   children: [
    //     {
    //       id: 'customer-list',
    //       title: <FormattedMessage id="All Customers" />,
    //       type: 'item',
    //       icon: icons.allcustomer,
    //       url: '/apps/customer/customer-list'
    //     }
    //     // {
    //     //   id: 'customer-card',
    //     //   title: <FormattedMessage id="cards" />,
    //     //   type: 'item',
    //     //   url: '/apps/customer/customer-card'
    //     // }
    //   ]
    // },
    // {
    //   id: 'invoice',
    //   title: <FormattedMessage id="invoice" />,
    //   url: '/apps/invoice/dashboard',
    //   type: 'collapse',
    //   icon: icons.invoice,
    //   breadcrumbs: true,
    //   children: [
    //     {
    //       id: 'create',
    //       title: <FormattedMessage id="create" />,
    //       type: 'item',
    //       url: '/apps/invoice/create'
    //     },
    // {
    //   id: 'details',
    //   title: <FormattedMessage id="details" />,
    //   type: 'item',
    //   url: '/apps/invoice/details/1'
    // },
    // {
    //   id: 'list',
    //   title: <FormattedMessage id="list" />,
    //   type: 'item',
    //   url: '/apps/invoice/list'
    // },
    // {
    //   id: 'Custom Invoices',
    //   title: <FormattedMessage id="Custom Invoices" />,
    //   type: 'item',
    //   url: '/apps/invoice/custom-invoices'
    // }
    // ]
    // },
    // {
    //   id: 'profile',
    //   title: <FormattedMessage id="profile" />,
    //   type: 'collapse',
    //   icon: icons.profile,
    //   children: [
    //     {
    //       id: 'user-profile',
    //       title: <FormattedMessage id="user-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/user/personal',
    //       breadcrumbs: false
    //     },
    //     {
    //       id: 'account-profile',
    //       title: <FormattedMessage id="account-profile" />,
    //       type: 'item',
    //       url: '/apps/profiles/account/basic'
    //     }
    //   ]
    // },

    // {
    //   id: 'Services',
    //   title: <FormattedMessage id="Services" />,
    //   type: 'collapse',
    //   icon: icons.ecommerce,
    //   children: [
    //     // {
    //     //   id: 'products',
    //     //   title: <FormattedMessage id="products" />,
    //     //   type: 'item',
    //     //   url: '/apps/e-commerce/products'
    //     // },
    //     // {
    //     //   id: 'product-details',
    //     //   title: <FormattedMessage id="product-details" />,
    //     //   type: 'item',
    //     //   url: '/apps/e-commerce/product-details/1',
    //     //   breadcrumbs: false
    //     // },
    //     {
    //       id: 'services-list',
    //       title: <FormattedMessage id="All Services" />,
    //       type: 'item',
    //       url: '/apps/e-commerce/services-list',
    //       breadcrumbs: false
    //     }
    //     // {
    //     //   id: 'add-new-product',
    //     //   title: <FormattedMessage id="add-new-product" />,
    //     //   type: 'item',
    //     //   url: '/apps/e-commerce/add-new-product'
    //     // },
    //     // {
    //     //   id: 'checkout',
    //     //   title: <FormattedMessage id="checkout" />,
    //     //   type: 'item',
    //     //   url: '/apps/e-commerce/checkout'
    //     // }
    //   ]
    // },
    {
      id: 'allorder',
      title: <FormattedMessage id="All order" />,
      type: 'item',
      url: '/apps/allorder',
      icon: icons.allorder,
      breadcrumbs: false
    },
    {
      id: 'allusers',
      title: <FormattedMessage id="All Users" />,
      type: 'item',
      url: '/apps/allusers',
      icon: icons.User,
      breadcrumbs: false
    },
    {
      id: 'contactus',
      title: <FormattedMessage id="Contact Us" />,
      type: 'item',
      url: '/apps/contactus',
      icon: icons.contact,
      breadcrumbs: false
    },
    {
      id: 'allproducts',
      title: <FormattedMessage id="All Products" />,
      type: 'item',
      url: '/apps/allproducts',
      icon: icons.contact,
      breadcrumbs: false
    },
    {
      id: 'shiprocket',
      title: <FormattedMessage id="ShipRocket" />,
      type: 'item',
      url: '/apps/shiprocket',
      icon: icons.ship,
      breadcrumbs: false
    },
    {
      id: 'paymentHistory',
      title: <FormattedMessage id="Payment History" />,
      type: 'item',
      url: '/apps/payment-history',
      icon: icons.MoneyChange,
      breadcrumbs: false
    }
    //   {
    //     id: 'allcoupons',
    //     title: <FormattedMessage id="All Coupons" />,
    //     type: 'item',
    //     url: '/apps/allcoupons',
    //     icon: icons.coupons,
    //     breadcrumbs: false
    //   },
    //   {
    //     id: 'blog',
    //     title: <FormattedMessage id="Blogs" />,
    //     type: 'item',
    //     url: '/apps/blog/Blog',
    //     icon: icons.blogs
    //   },
    //   {
    //     id: 'reviews',
    //     title: <FormattedMessage id="Reviews" />,
    //     type: 'item',
    //     url: '/apps/reviews',
    //     icon: icons.allorder
    //   },
    //   {
    //     id: 'complaint',
    //     title: <FormattedMessage id="complaint" />,
    //     type: 'item',
    //     url: '/apps/complaint',
    //     icon: icons.allorder
    //   }
  ]
};

export default applications;
